import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../styles/notice.css"

const notice = () => (
  <Layout>
    <SEO title="Notice" />
    <div className="mainContent">
      <div className="noticePage">
        <div className="content">
          <div className="noticeHeader">공고 / 공지</div>
          <div className="noticeBody">
            <div className="titleArea">
              <div className="title">신주발행공고(제3자배정)</div>
              <div className="date">2022-10-19</div>
            </div>
            <div className="textArea">
              <div className="contentsArea">
                <div className="textTitle">신주발행공고</div>
                <div className="textDesc">
                  <p>
                    2022년 10월 19일 개최한 주식회사 스테이지파이브 이사회에서 아래와 같이 제3자배정 방법으로 신주발행을
                    결의하였음을 공고합니다.
                  </p>
                  <br />
                  <p>
                    1. 신주의 종류와 수 : 상환전환우선주식9 40,481주
                    <br />
                    2. 신주의 발행가액 : 금 277,908원 (1주의 금액 금5,000원)
                    <br />
                    3. 신주의 납입기일 : 2022년 11월 3일
                    <br />
                    4. 신주의 인수방법 : 상법 제418조 및 정관 제9조 2항의 규정에 의하여 기존주주의 신주인수권을 배제하고, 신주
                    전부를 제3자에게 배정한다
                    <br />
                  </p>
                  <br />
                  <br />
                  <p className="footer">
                    2022년 10월 19일
                    <br />
                    주식회사 스테이지파이브 대표이사 서 상 원<br />
                    서울특별시 송파구 올림픽로35 123, 6층(신천동, 향군타워)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default notice
